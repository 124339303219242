<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Diploma Académico por Estudiante</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Registrar Diploma académico de gestiones pasadas</b>"
                        type="button"
                        @click="modalRegistraTitulo=true">
                  <font-awesome-icon icon="fa-plus"/>
                  Registrar Diploma
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>


                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Hoja ruta)
                    </a>
                  </CDropdownItem>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Eliminar)
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="jqxgrid"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalTitulo"
        size="xl"
    >
      <template #body-wrapper>
        <div class="row">
          <div class="col-4 text-center">
            <template v-if="titulo.titulo.length">
              <h5 class="text-center"><em class="text-danger">Diploma SERIE N°:</em>
                <template v-if="!actualizardatos"><br/><br/></template>
                {{ titulo.titulo[0].serie }}
                <template v-if="!actualizardatos"><br/><br/></template>
              </h5>
              <template v-if="!actualizardatos">
              <div class="row mb-3" v-if="serie!==''">
                <div class="col-3">
                  <a :href="pathImage(serie,1)" download="download" target="_blank" class="btn btn-outline-primary">
                    <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" size="xl" /><br/>
                    Descargar Imagen
                  </a>
                </div>
                <div class="col-9">
                  <a :href="pathImage(serie,1)" download="download" target="_blank">
                    <img :src="pathImage(serie,0.5)" alt="" class="img-responsive img-thumbnail"/>
                  </a>
                </div>
              </div>
              </template>
              <button v-if="!actualizardatos" type="button" class="btn btn-primary" @click="actualizardatos = true">
                Modificar datos del Diploma Académico
              </button>
            </template>
            <p v-else>No existen un diploma académico registrado</p>
            <div class="col-12 px-0" v-if="actualizardatos">
              <form ref="frmDatosTitulo" @submit.prevent="nuevoTitulo('0')" novalidate>
                <input type="hidden" v-model="datostitulo.codigo_alumno">
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="expedido" class="form-check-label" title="Ej: LP, OR, PT, CB, TJ, SC, etc.">Extension C.I.</label>
                  </div>
                  <div class="col-9">
                    <input v-model="datostitulo.expedido" id="expedido" class="form-control form-control-sm"
                           @change="datostitulo.expedido=datostitulo.expedido.toUpperCase()" required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="departamento" class="form-check-label">Departamento</label>
                  </div>
                  <div class="col-9">
                    <select v-model="datostitulo.departamento" id="departamento" class="form-control form-control-sm" required>
                      <option v-for="departamento in departamentos" :value="departamento">{{ departamento }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="provincia">Provincia</label>
                  </div>
                  <div class="col-9">
                    <input v-model="datostitulo.provincia" id="provincia" class="form-control form-control-sm" required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="rector">Rector</label>
                  </div>
                  <div class="col-9">
                    <input v-model="datostitulo.rector" id="rector" class="form-control form-control-sm" required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="rector_cargo">Cargo Rector</label>
                  </div>
                  <div class="col-9">
                    <input v-model="datostitulo.rector_cargo" id="rector_cargo" class="form-control form-control-sm" required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="urlqr">URL para Cod. QR
                      <a v-if="datostitulo.urlqr!==''" :href="datostitulo.urlqr" target="_blank">(Ver)</a>
                    </label>
                  </div>
                  <div class="col-9">
                    <textarea v-model="datostitulo.urlqr" id="urlqr" class="form-control form-control-sm" required/>
                  </div>
                </div>
                <div class="row mb-1" v-if="serie!==''">
                  <div class="col-3">
                    <a :href="pathImage(serie,1)" download="download" target="_blank" class="btn btn-outline-primary">
                      <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" size="xl" /><br/>
                      Descargar Imagen
                    </a>
                  </div>
                  <div class="col-9">
                    <a :href="pathImage(serie,1)" download="download" target="_blank">
                    <img :src="pathImage(serie,0.5)" alt="" class="img-responsive img-thumbnail">
                    </a>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary btn-block">
                      Registrar Diploma Académico
                    </button>
                  </div>
                  <template v-if="titulo.titulo.length">
                    <div class="col-6">
                      <button type="submit" class="btn btn-outline-dark btn-block" @click="actualizardatos=false">
                        No modificar Diploma
                      </button>
                    </div>
                  </template>
                </div>
              </form>
            </div>
          </div>
          <div class="col-8">
            <embed ref="reportePdf" height="550" src="" type="application/pdf" title="pdf.pdf"
                   width="100%"/>
          </div>
        </div>
      </template>
      <template #header>
        <h4>{{ alumno }}
        </h4>
        <CButtonClose @click="modalTitulo = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalRegistraTitulo"
        size="xl"
    >
      <template>
        <div class="row pl-2">
          <div class="col-12 text-center">
              <form ref="frmDatosTituloExistente" @submit.prevent="registraTituloExistente()" novalidate>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_codigo_alumno" class="form-check-label" title="Código de alumno que ya posee diploma académico">Códgo de Alumno</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.codigo_alumno" id="titulo_codigo_alumno"
                           class="form-control" required @change="buscarAlumno"/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_nombre_estudiante" class="form-check-label">Nombre estudiante</label>
                  </div>
                  <div class="col-9">
                    <input v-model="titulo_nombre_estudiante" id="titulo_nombre_estudiante"
                           class="form-control-plaintext" readonly required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_serie">Nro. Diploma</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.serie" id="titulo_serie" class="form-control" required/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_created_at">Fecha Título</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.created_at" id="titulo_created_at" class="form-control" type="date"/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_modalidad">Modalidad</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.modalidad" id="titulo_modalidad" class="form-control"/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_nota">Nota</label>
                  </div>
                  <div class="col-9">
                      <input v-model="tituloexistente.nota" id="titulo_nota" min="0" max="100" type="number" class="form-control"/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_titulo_tema">Título Tema</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.titulo_tema" id="titulo_titulo_tema" class="form-control"/>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-3 pr-0">
                    <label for="titulo_urlqr">URL para Cod. QR</label>
                  </div>
                  <div class="col-9">
                    <input v-model="tituloexistente.urlqr" id="titulo_urlqr" class="form-control"/>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary btn-block">
                      Registrar Diploma Académico
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </template>
      <template #header>
        <h4>Registrar Diploma Académico emitido en anteriores gestiones</h4>
        <CButtonClose @click="modalRegistraTitulo = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </CRow>
</template>
<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import axios from "axios";
import Forms from "@/views/base/Forms";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    Forms,
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      modalTitulo: false,
      modalRegistraTitulo: false,
      actualizardatos: true,
      titulo: {
        alumno: [],
        titulo: [],
        titulos: [],
      },
      tituloexistente:{
        codigo_alumno:'',
        titulo_tema:'',
        modalidad:'',
        urlqr:'',
        nota:0,
        created_at:'',
        serie:'',
      },
      titulo_nombre_estudiante:'',
      codigo_alumno: '',
      alumno: '',
      tituloseleccionado: '',
      serie: '',
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      departamentos: ['La Paz', 'Beni', 'Cochabamba', 'Chuquisaca', 'Oruro', 'Pando', 'Potosi', 'Santa Cruz', 'Tarija',':: NINGUNO ::'],
      datostitulo: {
        codigo_alumno: "",
        departamento: "La Paz",
        provincia: "",
        rector: "Msc. Pedro Quiroz Calle",
        rector_cargo: "Rector UB",
        urlqr: ''
      },
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '7%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '11%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '11%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '14%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '17%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'materias_carrera', datafield: "materias_carrera", width: '7%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materias de<br />la carrera</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'materias_aprobadas', datafield: "materias_aprobadas", width: '7%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materias<br />aprobadas</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Nro. Diploma ', datafield: "titulo_serie", width: '12%',cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nro. Diploma"});
          }
        },
        {
          text: "", datafield: 'hojaruta', width: '14%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.estado === 'Y') ? "Diploma Académico" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            var rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.codigo_alumno = rowData.codigo_alumno;
            modalPdf.alumno = rowData.nombres + ' ' + rowData.apellido1 + ' ' + rowData.apellido2;
            modalPdf.modalTitulo = true;
            modalPdf.gestionseleccionada = ''
            modalPdf.serie = ''
            axios.get(
                address + '/api/docentereportes/' + rowData.codigo_alumno + '/titulos?token=' + localStorage.getItem("api_token")
            )
                .then((response) => {
                  modalPdf.datostitulo.codigo_alumno = rowData.codigo_alumno
                  modalPdf.titulo = response.data;
                  modalPdf.datostitulo.departamento = modalPdf.titulo.alumno[0].departamento
                  modalPdf.datostitulo.provincia = modalPdf.titulo.alumno[0].provincia
                  if (modalPdf.titulo.titulo.length > 0) {
                    modalPdf.datostitulo.rector = modalPdf.titulo.titulo[0].rector
                    modalPdf.datostitulo.rector_cargo = modalPdf.titulo.titulo[0].rector_cargo
                    modalPdf.datostitulo.urlqr = modalPdf.titulo.titulo[0].urlqr
                    modalPdf.actualizardatos = false;
                    modalPdf.mostrartitulo(modalPdf.titulo.titulo[0].serie)
                    modalPdf.serie = modalPdf.titulo.titulo[0].serie
                  } else {
                    modalPdf.actualizardatos = true;
                  }
                })
                .catch(function (error) {
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentereportes/listaralumnosdiplomaacademico?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'titulo_serie', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'materias_carrera', type: "int"},
        {name: 'materias_aprobadas', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.jqxgrid.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.jqxgrid.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.jqxgrid.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.jqxgrid.hidecolumn('documentos');
      this.$refs.jqxgrid.hidecolumn('editar');
      this.$refs.jqxgrid.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.jqxgrid.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.jqxgrid.showcolumn('documentos');
      this.$refs.jqxgrid.showcolumn('editar');
      this.$refs.jqxgrid.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.jqxgrid.clearfilters();
    },
    sizeChange: function (event) {
      try {
        this.filasPorPagina = event.target.value;
        if (this.filasPorPagina > 10) {
          this.$refs.jqxgrid.height = (50 * this.filasPorPagina) + 118;
          this.$refs.jqxgrid.rowsHeight = 50;
          this.$refs.jqxgrid.autoheight = false;
          this.$refs.jqxgrid.autorowheight = false;
        }
        this.$refs.jqxgrid.pagesize = parseInt(this.filasPorPagina);
        if (this.filasPorPagina <= 10) {
          this.$refs.jqxgrid.autoheight = true;
          this.$refs.jqxgrid.autorowheight = true;
        }
        this.$refs.jqxgrid.updatebounddata()
      } catch (e) {

      }
    },
    listartitulos: function (codigo_alumno) {
      axios.get(
          address + '/api/docentereportes/' + codigo_alumno + '/titulos?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            modalPdf.datostitulo.codigo_alumno = rowData.codigo_alumno
            modalPdf.titulo = response.data;
            modalPdf.datostitulo.departamento = modalPdf.titulo.alumno[0].departamento
            modalPdf.datostitulo.provincia = modalPdf.titulo.alumno[0].provincia
            if (modalPdf.titulo.titulo.length > 0) {
              modalPdf.datostitulo.rector = modalPdf.titulo.titulo[0].rector
              modalPdf.datostitulo.rector_cargo = modalPdf.titulo.titulo[0].rector_cargo
              modalPdf.datostitulo.urlqr = modalPdf.titulo.titulo[0].urlqr
              modalPdf.actualizardatos = false;
              modalPdf.mostrartitulo(modalPdf.titulo.titulo[0].serie)
              modalPdf.serie = modalPdf.titulo.titulo[0].serie
            } else {
              modalPdf.actualizardatos = true;
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    nuevoTitulo: function (serie) {
      if (!modalPdf.$refs.frmDatosTitulo.checkValidity()) {
        modalPdf.$refs.frmDatosTitulo.classList.add('was-validated');
      } else {
        modalPdf.modalTitulo = false;
        modalPdf.$refs.confirm.confirm('', '¿Crear nuevo Título Profesional para ' + modalPdf.alumno + '?', function () {
          modalPdf.$refs.alert.show('Registrando Título');
          axios.post(
              address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + serie + '/tituloregistrar?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada,
              modalPdf.datostitulo
          )
              .then((response) => {
                modalPdf.actualizardatos = false;
                modalPdf.$refs.jqxgrid.updatebounddata();
                modalPdf.mostrartitulo(response.data.codigo_alumno)
              })
              .catch(function (error) {
                modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
              })
              .finally(function () {
                modalPdf.$refs.alert.hide();
                modalPdf.modalTitulo = false;
              })
        }, function () {
          modalPdf.modalTitulo = true;
        })
      }
    },
    mostrartitulo: function (serie) {
      if (serie !== '') {
        modalPdf.modalTitulo = false;
        modalPdf.$refs.reportePdf.setAttribute('src', '')
        modalPdf.$refs.alert.show('Descargando documento');
        axios.get(
            address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + serie + '/tituloacademicopdf?token=' + localStorage.getItem("api_token"),
            {responseType: 'blob'}
        )
            .then((response) => {
              modalPdf.$refs.reportePdf.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
              modalPdf.listartitulos(modalPdf.codigo_alumno)
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalTitulo = true;
            })
      }
    },
    pathImage: function (serie,porcentaje) {
      if (serie !== '') {
        return this.$apiAdress + '/api/titulodrive/generadiplomadrive/' + serie + '/' + porcentaje + '?token=' + localStorage.getItem('api_token') + '&_r=' + Math.floor(Math.random() * 100) + 1
      } else {
        return ""
      }
    },
    buscarAlumno: function () {
      modalPdf.tituloexistente.serie=modalPdf.tituloexistente.codigo_alumno+'-'
      axios.get(
          this.$apiAdress + '/api/docentereportes/' + modalPdf.tituloexistente.codigo_alumno + '/buscaralumno?token=' + localStorage.getItem("api_token"))
          .then((response) => {
            modalPdf.titulo_nombre_estudiante=response.data.titulo_nombre_estudiante
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          })
          .finally(function () {
          })
    },
    registraTituloExistente: function () {
      var left = this
      if (!left.$refs.frmDatosTituloExistente.checkValidity()) {
        left.$refs.frmDatosTituloExistente.classList.add('was-validated');
      } else {
        axios.post(
            this.$apiAdress + '/api/docentereportes/' + left.tituloexistente.codigo_alumno + '/registratituloexistente?token=' + localStorage.getItem("api_token"),
            left.tituloexistente
        )
            .then((response) => {console.log(left.$refs.jqxgrid);
              left.$refs.jqxgrid.updatebounddata();
            })
            .catch(function (error) {
              left.$refs.mensajeToast.mostrarError(error, left);
            })
            .finally(function () {
              left.modalRegistraTitulo = false
            })
      }
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>